// src/App.js
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import HomePage from './HomePage';
import PrivacyPage from './PrivacyPolicy';
import CookieConsent from 'react-cookie-consent';

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/privacy-policy" element={<PrivacyPage />} />
            </Routes>
            <CookieConsent
        location="bottom"
        buttonText="Yeah, whatever"
        cookieName="myAppCookieConsent"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        This website uses cookies to enhance the user experience.{" "}
        <a href="/privacy-policy" style={{ color: "#e0e0e0" }}>Learn More</a>
      </CookieConsent>
        </BrowserRouter>
    );
}

export default App;
