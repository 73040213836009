import React, { useRef, useEffect, useState } from 'react';
import { Canvas, useFrame, extend } from '@react-three/fiber';
import { OrbitControls, shaderMaterial } from '@react-three/drei';
import * as THREE from 'three';
import styles from '../css/HeaderSection.module.css';
import { ReactComponent as Logo } from '../images/logo.svg';
import { ReactComponent as ScrollDownIcon } from '../images/scroll-down.svg';

// Vertex Shader
const vertexShader = `
precision mediump float;
#define NUM_MARKERS 10
uniform vec3 markerPositions[NUM_MARKERS];
uniform float time;
varying float vDist;

float hash(vec3 p) {
  p = fract(p * 0.3183099 + 0.1);
  p *= 17.0;
  return fract(p.x * p.y * p.z * (p.x + p.y + p.z));
}

void main() {
  vec3 newPosition = position;
  float minDist = 1000.0;

  for (int i = 0; i < NUM_MARKERS; i++) {
    vec3 seg = position - markerPositions[i];
    float dist = length(seg);
    float force = clamp(1.0 / (dist * dist * 0.25), 0.0, 1.0);
    newPosition += normalize(seg) * force;
    minDist = min(minDist, dist);
  }

  float displacement = hash(position + time);
  newPosition += normal * displacement * 0.5;

  vDist = minDist;

  gl_PointSize = 2.0;
  gl_Position = projectionMatrix * modelViewMatrix * vec4(newPosition, 1.0);
}
`;

// Fragment Shader
const fragmentShader = `
precision mediump float;
uniform float time;
varying float vDist;

void main() {
  vec3 oceanBlue = vec3(0.0, 0.48, 1.0);
  vec3 darkerWhite = vec3(0.8, 0.8, 0.8);
  float fluctuation = 0.5 + 0.5 * sin(time * 0.1);
  vec3 color = mix(oceanBlue, darkerWhite, fluctuation);
  gl_FragColor = vec4(color, 1.0);
}
`;

// Create a custom shader material
const CustomShaderMaterial = shaderMaterial(
    {
        time: 0,
        markerPositions: [],
    },
    vertexShader,
    fragmentShader
);

extend({ CustomShaderMaterial });

function Points() {
    const pointsRef = useRef();
    const numMarkers = 10;

    const geometry = React.useMemo(() => {
        const n = 10000; // Adjust if needed
        const dri = 1;
        const r = 4;
        const dro = 1;
        const pts = [];
        for (let i = 0; i < n; i++) {
            const inout = (Math.random() - 0.5) * 2;
            const lim = inout >= 0 ? dro : dri;
            const rand = r + Math.pow(Math.random(), 3) * lim * inout;

            const theta = Math.PI * 2 * Math.random();
            const phi = Math.acos(2 * Math.random() - 1);

            const ps = new THREE.Vector3(
                Math.cos(theta) * Math.sin(phi),
                Math.sin(theta) * Math.sin(phi),
                Math.cos(phi)
            );
            pts.push(ps.multiplyScalar(rand));
        }
        const geometry = new THREE.BufferGeometry().setFromPoints(pts);
        return geometry;
    }, []);

    const markerPositions = useRef(
        Array.from({ length: numMarkers }, () => new THREE.Vector3())
    );

    const uniforms = React.useMemo(
        () => ({
            time: { value: 0 },
            markerPositions: { value: markerPositions.current },
        }),
        []
    );

    useFrame((state) => {
        const t = state.clock.getElapsedTime();
        uniforms.time.value = t;

        // Precompute time multipliers
        const times = {
            t02: t * 0.2,
            t03: t * 0.3,
            t04: t * 0.4,
            t05: t * 0.5,
            t06: t * 0.6,
            t07: t * 0.7,
            t08: t * 0.8,
            t09: t * 0.9,
        };

        // Precompute sin and cos values
        const sin = {};
        const cos = {};
        Object.keys(times).forEach((key) => {
            sin[key] = Math.sin(times[key]) * 5;
            cos[key] = Math.cos(times[key]) * 5;
        });

        const markers = markerPositions.current;

        // Update marker positions using precomputed values
        markers[0].x = sin.t05;
        markers[0].y = cos.t03;

        markers[1].x = cos.t07;
        markers[1].y = sin.t05;
        markers[1].z = sin.t03;

        markers[2].x = sin.t04;
        markers[2].z = cos.t06;

        markers[3].y = sin.t06;
        markers[3].z = cos.t08;

        markers[4].x = sin.t09;
        markers[4].y = cos.t02;
        markers[4].z = sin.t05;

        markers[5].x = cos.t03;
        markers[5].y = sin.t07;

        markers[6].x = sin.t08;
        markers[6].z = cos.t04;

        markers[7].y = cos.t05;
        markers[7].z = sin.t09;

        markers[8].x = sin.t02;
        markers[8].y = cos.t06;

        markers[9].x = cos.t05;
        markers[9].y = sin.t04;
        markers[9].z = cos.t07;
    });

    return (
        <points ref={pointsRef} scale={[1, 1, 1]}>
            <bufferGeometry attach="geometry" {...geometry} />
            <customShaderMaterial
                attach="material"
                uniforms={uniforms}
                transparent
                depthTest={false}
            />
        </points>
    );
}

const HeaderSection = () => {
    const headerRef = useRef();
    const [isTouchDevice, setIsTouchDevice] = useState(false);
    const [isLowPowerDevice, setIsLowPowerDevice] = useState(false);

    useEffect(() => {
        const hasTouchScreen =
            'ontouchstart' in window || navigator.maxTouchPoints > 0;
        setIsTouchDevice(hasTouchScreen);

        const lowPowerDevice = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        setIsLowPowerDevice(lowPowerDevice);
    }, []);

    return (
        <header ref={headerRef} className={styles.headerSection}>
            <div className='row'>
            <Canvas
                className={styles.canvasContainer}
                camera={{ position: [0, 0, 15], fov: 60 }}
                dpr={[1, 1.5]}
                gl={{ antialias: false }}
            >
                <Points />
                {!isTouchDevice && !isLowPowerDevice && <OrbitControls enableZoom={false} />}
            </Canvas>
            <div className={styles.logoContainer}>
                <Logo className={styles.companyLogo} />
            </div>
            <ScrollDownIcon className={styles.scrollDownIcon} aria-hidden="true" />
            </div>
        </header>
    );
};

export default HeaderSection;
