import React from 'react';
import { useForm } from 'react-hook-form';
import styles from '../css/ContactForm.module.css';
import { ReactComponent as ArrowRightIcon } from '../images/arrow-right.svg';

const ContactForm = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit = (data) => {
        console.log(data);
        // Form submission logic
    };

    return (
        <section className={styles.contactFormSection}>
            <div className='row'>
            <h1 className={styles.formTitle}>Contact Us</h1>
            <p className={styles.formSubtitle}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </p>
            <form className={styles.form} onSubmit={handleSubmit(onSubmit)} noValidate>
                <label className={styles.label}>
                    <input
                        type="text"
                        placeholder="Your Name"
                        className={styles.inputField}
                        {...register('name', {required: 'Name is required'})}
                        aria-invalid={errors.name ? 'true' : 'false'}
                    />
                    {errors.name && <span className={styles.errorText}>{errors.name.message}</span>}
                </label>

                <label className={styles.label}>
                    <input
                        type="email"
                        placeholder="Your Email"
                        className={styles.inputField}
                        {...register('email', {
                            required: 'Email is required',
                            pattern: {value: /^\S+@\S+$/i, message: 'Invalid email address'},
                        })}
                        aria-invalid={errors.email ? 'true' : 'false'}
                    />
                    {errors.email && <span className={styles.errorText}>{errors.email.message}</span>}
                </label>

                <label className={styles.label}>
                    <textarea
                        placeholder="Your Message"
                        className={styles.textArea}
                        {...register('message', {required: 'Message is required'})}
                        aria-invalid={errors.message ? 'true' : 'false'}
                    />
                    {errors.message && (
                        <span className={styles.errorText}>{errors.message.message}</span>
                    )}
                </label>

                <button type="submit" className={styles.submitButton}>
                    <ArrowRightIcon className={styles.buttonIcon} aria-hidden="true"/>
                    Submit
                </button>

                <div className={styles.checkboxes}>
                    <div className={styles.checkboxGroup}>
                        <label className={styles.checkboxLabel}>
                            <input
                                type="checkbox"
                                {...register('acceptTerms', {required: 'You must accept the terms'})}
                            />
                            <span className={styles.customCheckbox}></span>
                            *I accept the{'\u00A0'}
                            <a href="/terms" className={styles.link}>
                                Terms
                            </a>
                        </label>
                        {errors.acceptTerms && (
                            <span className={styles.errorText}>{errors.acceptTerms.message}</span>
                        )}
                    </div>

                    <div className={styles.checkboxGroup}>
                        <label className={styles.checkboxLabel}>
                            <input
                                type="checkbox"
                                {...register('subscribeNewsletter')}
                            />
                            <span className={styles.customCheckbox}></span>
                            Register for the newsletter
                        </label>
                    </div>
                </div>
            </form>
            </div>
        </section>
    );
};

export default ContactForm;
