import React from 'react';
import styles from '../css/FooterSection.module.css';
import { ReactComponent as Logo } from '../images/logo.svg';
import { ReactComponent as ArrowRightIcon } from '../images/arrow-right.svg';

const FooterSection = () => (
    <div className={styles.footerContainer}>
        <div className={styles.footerGlow}></div>
        <footer className={styles.footerSection}>
            <div className={styles.footerTop}>
                <div className={styles.logoAndSubscription}>
                    <Logo className={styles.companyLogo} />
                    <h3 className={styles.newsletterTitle}>Subscribe to Our Newsletter</h3>
                    <form className={styles.subscriptionForm}>
                        <input
                            type="email"
                            placeholder="Your Email"
                            className={styles.subscriptionInput}
                        />
                        <button type="submit" className={styles.subscriptionButton}>
                            <ArrowRightIcon className={styles.buttonIcon} aria-hidden="true"/>
                            Subscribe
                        </button>
                    </form>
                    <p className={styles.privacyText}>
                        By subscribing you agree with our{' '}
                        <a href="/privacy-policy" className={styles.link}>
                            Privacy Policy
                        </a>
                    </p>
                </div>
                <div className={styles.footerLinks}>
                    <a href="/privacy-policy" className={styles.link}>
                        Privacy Policy
                    </a>
                    <a href="/privacy-policy" className={styles.link}>
                        Terms of Service
                    </a>
                    <a href="/cookies" className={styles.link}>
                        Cookie Settings
                    </a>
                </div>
            </div>
            <div className={styles.footerBottom}>
                <p className={styles.centeredText}>
                    © 2024 Bluemint x Sidas VIsual. All rights reserved.
                </p>
            </div>
        </footer>
    </div>
);

export default FooterSection;
