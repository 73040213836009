import React, {useRef} from 'react';
import styles from '../css/MiniHeaderSection.module.css';
import { ReactComponent as Logo } from '../images/logo.svg';
import { ReactComponent as ArrowRightIcon } from '../images/arrow-right.svg';



const MiniHeaderSection = () => {

    const headerRef = useRef();
    return (
        <header ref={headerRef} className={styles.miniHeaderContainer}>
        <div className={styles.row}>
        <div className={styles.logoContainer}>
            <Logo className={styles.companyLogo} />
        </div>

        </div>
    </header>
    );
};
    

export default MiniHeaderSection;
