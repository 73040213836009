import React from 'react';
import ContactForm from './components/ContactForm';
import FooterSection from './components/FooterSection';
import styles from './css/Cms.module.css';
import Head from './components/Head';
import MiniHeaderSection from './components/MiniHeader';


const PrivacyPage = () => (
    <div className={styles.wrapper}>
       <MiniHeaderSection/>
      <Head title="Bluemint" description="" keywords="bluemint, solution" />
        <div className={styles.row}>
        <h1>Privacy Policy</h1>
    <p><strong>Last Updated:</strong> 2024 11 01</p>

    <p>Your privacy is important to us. This Privacy Policy explains how we collect, use, and protect your information when you use our application. Our app currently uses Google Tag Manager (GTM) to improve user experience and understand general usage patterns.</p>

    <div className="paragraph">
        <h2>1. Information We Collect</h2>
        <p>We collect certain information about your interaction with our app through Google Tag Manager (GTM). GTM itself does not collect personal data; rather, it facilitates the integration of other tracking tools. At this time, no other tracking or analytics tools are integrated, so no personal or identifiable information is collected.</p>

        <h3>1.1 Automatically Collected Information</h3>
        <p>When you use our app, certain data is automatically collected to help us understand how you interact with our application. This information includes:</p>
        <ul>
            <li><strong>Device Information:</strong> Type of device, operating system, and browser type.</li>
            <li><strong>Usage Information:</strong> Information about how you interact with our app, such as pages viewed, time spent, and clicks.</li>
        </ul>
        <p>This information is anonymized and aggregated and does not personally identify you.</p>
    </div>

    <div className="paragraph">
        <h2>2. Use of Cookies</h2>
        <p>Our app uses cookies solely to enhance your user experience. A cookie is a small file that is stored on your device. We use the following types of cookies:</p>
        <ul>
            <li><strong>Essential Cookies:</strong> These cookies are necessary for the app to function. They include cookies that remember your consent to use cookies.</li>
        </ul>
        <p>Google Tag Manager may use cookies to help manage and deploy tags, but these cookies do not store any personally identifiable information.</p>

        <h3>Managing Cookies</h3>
        <p>You can disable cookies through your browser settings. However, please note that doing so may affect the functionality of the app.</p>
    </div>

    <div className="paragraph">
        <h2>3. How We Use the Information</h2>
        <p>We use the information collected through Google Tag Manager for the following purposes:</p>
        <ul>
            <li><strong>Improving User Experience:</strong> To better understand how users interact with our app, so we can enhance its functionality and usability.</li>
            <li><strong>Performance Analysis:</strong> To analyze app performance and make necessary adjustments to improve efficiency and reliability.</li>
        </ul>
    </div>

    <div className="paragraph">
        <h2>4. Third-Party Services</h2>
        <p><strong>Google Tag Manager</strong></p>
        <p>Our app uses Google Tag Manager to manage and implement tracking tags efficiently. Google Tag Manager does not collect personal information itself but can manage and deploy other tags that may collect data. Currently, we do not deploy additional tags or analytics through GTM, so no personal information is collected.</p>
        <p>For more information on Google Tag Manager’s privacy practices, please refer to <a href="https://policies.google.com/privacy" target="_blank">Google's Privacy Policy</a>.</p>
    </div>

    <div className="paragraph">
        <h2>5. Data Security</h2>
        <p>We are committed to protecting the security of your data. While no online service is 100% secure, we take reasonable precautions to safeguard your information and maintain a secure environment for data collection through Google Tag Manager.</p>
    </div>

    <div className="paragraph">
        <h2>6. Your Rights</h2>
        <p>Depending on your location, you may have certain rights regarding your personal data, such as:</p>
        <ul>
            <li><strong>Access:</strong> You can request information about the data collected.</li>
            <li><strong>Deletion:</strong> You can request that we delete any information collected (although no personal data is currently collected).</li>
            <li><strong>Objection:</strong> You can object to the data collection practices we use (e.g., disable cookies).</li>
        </ul>
        <p>To exercise any of these rights, please contact us at <a href="mailto:arnoldas@bluemint.dev">email</a>.</p>
    </div>

    <div className="paragraph">
        <h2>7. Changes to This Privacy Policy</h2>
        <p>We may update this Privacy Policy periodically. If we make changes, we will notify you by updating the date at the top of this policy. We encourage you to review this Privacy Policy whenever you use our app to stay informed about our data practices.</p>
    </div>

    
        </div>
        <FooterSection />
    </div>
);

export default PrivacyPage;
