import React from 'react';
import HeaderSection from './components/HeaderSection';
import ContactForm from './components/ContactForm';
import FooterSection from './components/FooterSection';
import styles from './css/ContactPage.module.css';
import Head from './components/Head';


const ContactPage = () => (
    <div className={styles.contactPage}>
      <Head title="Bluemint" description="" keywords="bluemint, solution" />
        <HeaderSection />
        <ContactForm />
        <FooterSection />
    </div>
);

export default ContactPage;
